import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import GreenPoint from '../../assets/images/green-point.svg'
import ChevronRightGreen from '../../assets/images/chevron-right-green.svg'
import EnrollmentMainImage from '../../assets/images/enrollment-main-image.png'
import EnrollmentMainText from '../../assets/images/enrollment-main-text-container.png'
import EnrollmentNowImage from '../../assets/images/enrollment-now-section-image.png'
import BigManWorld from '../../assets/images/big-man-world.png'
import VrOculus from '../../assets/images/oculus-vr-headset.png'

import GetReferenceCode from '../../components/MatchReferenceCode/MatchReferenceCode'
import '../../assets/styles/components/plans-cards.scss'
import './enrollment.scss'

const Enrollment = _ => {
  const id = GetReferenceCode()

  return (
    <main className="home-main enrollment">
      <section className="background-color--dark-purple enrollment__hero">
        <div className="width-content enrollment__hero__content">
          <div className="enrollment__hero__content__description">
            <h1 className="title--xxl text--gray-blue enrollment__hero__title">Bienvenid@ a EpicGroup Lab Houston</h1>
            <p className="subtitle text--gray-blue enrollment__hero__content__description__text">
              ¡Bienvenido a nuestro centro!<br />
              <br />
              Aquí podrás conocer nuestros cursos online de emprendimiento y tecnología.<br />¡Empieza a construir tu futuro con
              nosotros!
            </p>
            <p className="subtitle text--gray-blue enrollment__hero__content__description__help-text">
              Si este no es tu lugar, vuelve a Central
            </p>
            <a className="enrollment__hero__content__center-list__center" href="https://epicgrouplab.com/enrollment">
              <img src={GreenPoint} alt="point" />
              <p className="paragraph--standard text--gray-blue">EpicGroup Lab Central</p>
              <img src={ChevronRightGreen} alt="chevron-right" />
            </a>
          </div>
          <div className="enrollment__hero__content__container-visiting">
            <div className="enrollment__hero__content__visiting" style={{ backgroundImage: `url(${EnrollmentMainImage})` }}>
              <div className="enrollment__hero__content__visiting-text-block" style={{ backgroundImage: `url(${EnrollmentMainText})` }}>
                <p className="text--gray-blue enrollment__hero__content__visiting-text-block__text">Visita las oficinas</p>
              </div>
            </div>
            <button className="button--first--large">
              <p className="paragraph--highlight">Visita la oficina virtual</p>
            </button>
          </div>
        </div>
      </section>

      <section className="enrollment__right-now">
        <div className="width-content enrollment__right-now__content">
          <div>
            <figure className="enrollment__right-now__content__image">
              <img src={EnrollmentNowImage} alt="virtual-reality-room" />
            </figure>
          </div>
          <div className="enrollment__right-now__description">
            <h2 className="title--xxl text--dark-purple">¡Matricúlate ahora!</h2>
            <p className="subtitle text--dark-purple enrollment__right-now__description__text">
              Matricúlate en nuestros <strong>cursos online</strong> y empieza en camino hacia tu futuro como emprendedor.<br />
              <br />
              Aprende sobre <strong>emprendimiento y tecnología en el Metaverso</strong>, y pertenece a la <strong>
              comunidad</strong> Epic.
            </p>
            <button className="button--first--large">
              <p className="paragraph--highlight">Solicita una clase demo gratis</p>
            </button>
          </div>
        </div>
      </section>

      <section className="background-color--dark-purple enrollment__available-courses">
        <div className="width-content enrollment__available-courses__content">
          <div className="enrollment__available-courses__description-container">
            <h2 className="title--standard enrollment__available-courses__title">Cursos disponibles</h2>
            <p className="subtitle">
              En EpicGroup Lab Houston contamos con un curso de emprendimiento y otro de emprendimiento + tecnología.<br />
              <br />
              Si te activas mediante el pago trimestral...<strong>¡No tendrás que pagar matrícula!</strong>
            </p>
          </div>

          <div className="enrollment__available-courses__plans-container">
            <div className="plans-offer-two-sections-container entrepreneurship-technology-registration-plan">
              <article className="plans-to-buy__list__epic-plan">
                <div className="plans-to-buy__list__epic-plan__title-container">
                  <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                    Curso<br />Emprendimiento Digital + Tecnología Online
                  </h3>
                </div>

                <p className="text--light-blue paragraph--standard">
                  <strong>Curso</strong><br />
                  <br />
                  El curso de emprendimiento digital + tecnología online incluye las clases semanales de la comunidad.
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">Valor</p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">$175</p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;pago mensual, impuestos no incluidos
                  </small>
                </div>

                <a
                className="plans-to-buy__list__epic-plan__more-information"
                href="https://epicgrouplab.com/courses">
                  <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                    Conoce el plan trimestral
                  </p>
                  <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                    <img src={ChevronRightGreen} alt="chevron-right-green" />
                  </figure>
                </a>

                <Link
                  className="button--third--small plans-to-buy__list__epic-plan__button"
                  to={`/sign-up-entrepreneurship-technology/${id}`}
                >
                  <p className="paragraph--highlight">Matricularse</p>
                </Link>
              </article>
            </div>

            <article className="plans-to-buy__list__epic-plan individual-more-hours-plan">
              <div className="plans-to-buy__list__epic-plan__title-container">
                <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                  Curso<br />Emprendimiento Digital + Tecnología Online
                </h3>
              </div>

              <p className="text--light-blue paragraph--standard">
                <strong>Curso</strong><br />
                <br />
                El curso de emprendimiento digital + tecnología online incluye las clases semanales de la comunidad.
              </p>
              <br />
              <p className="text--green plans-to-buy__list__epic-plan__description">Valor</p>
              <div className="plans-to-buy__list__call-to-action">
                <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">$500</p>
                <small className="text--light-blue plans-to-buy__list__epic-plan__time">&nbsp;&nbsp;pago trimestral, impuestos no incluidos</small>
              </div>

              <a
              className="plans-to-buy__list__epic-plan__more-information"
              href="https://epicgrouplab.com/courses">
                <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                  Conoce el plan trimestral
                </p>
                <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                  <img src={ChevronRightGreen} alt="chevron-right-green" />
                </figure>
              </a>

              <Link
                className="button--third--small plans-to-buy__list__epic-plan__button"
                to={`/sign-up-entrepreneurship-technology/${id}`}
              >
                <p className="paragraph--highlight">Matricularse</p>
              </Link>
            </article>

            <div className="plans-container__middle-line"></div>

            <div className="plans-offer-two-sections-container english-online-monthly">
              <article className="plans-to-buy__list__epic-plan">
                <div className="plans-to-buy__list__epic-plan__title-container">
                  <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                    Curso
                    <br />
                    Inglés VR Online
                  </h3>
                </div>

                <p className="text--light-blue paragraph--standard">
                  <strong>Matrícula</strong><br />
                  <br />
                  Esta llave te dará acceso anual a la comunidad y el pase VIP para eventos virtuales de Epic.
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  Valor
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    $100
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;pago único, impuestos no incluidos
                  </small>
                </div>
              </article>

              <div className="plans-to-buy__middle-line"></div>

              <article className="plans-to-buy__list__epic-plan">
                <p className="text--light-blue paragraph--standard">
                  <strong>Curso</strong><br />
                  <br />
                  El curso de inglés vr online incluye 2 clases a la semana.
                </p>
                <p className="text--light-blue text--small">
                  <br />
                  Puedes tomar el curso completamente en línea si tienes gafas de VR (Oculus Quest 2).
                  Si no las tienes, puedes asistir en persona a tu sede más cercana, donde tendrás acceso
                  a dispositivos VR, con mentores en directo online.
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  Valor
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    $100
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;pago mensual, impuestos no incluidos
                  </small>
                </div>

                {/* <a
                className="plans-to-buy__list__epic-plan__more-information"
                href="https://epicgrouplab.com/courses">
                  <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                    Conoce el plan trimestral
                  </p>
                  <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                    <img src={ChevronRightGreen} alt="chevron-right-green" />
                  </figure>
                </a> */}

                <Link
                className="button--third--small plans-to-buy__list__epic-plan__button"
                to={`/sign-up-english-online-monthly/${id}`}>
                  <p className="paragraph--highlight">
                    Matricularse
                  </p>
                </Link>
              </article>
            </div>

            <article className="plans-to-buy__list__epic-plan english-online-quarterly">
              <div className="plans-to-buy__list__epic-plan__title-container">
                <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                  Curso
                  <br />
                  Inglés VR Online
                </h3>
              </div>
              <p className="text--light-blue paragraph--standard">
                <strong>Curso</strong><br />
                <br />
                El curso de inglés vr online incluye 2 clases a la semana.
              </p>
              <p className="text--light-blue text--small">
                <br />
                Puedes tomar el curso completamente en línea si tienes gafas de VR (Oculus Quest 2).
                Si no las tienes, puedes asistir en persona a tu sede más cercana, donde tendrás acceso
                a dispositivos VR, con mentores en directo online.
              </p>
              <br />
              <p className="text--green plans-to-buy__list__epic-plan__description">
                Valor
              </p>
              <div className="plans-to-buy__list__call-to-action">
                <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                  $290
                </p>
                <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                  &nbsp;&nbsp;pago trimestral, impuestos no incluidos
                </small>
              </div>

              {/* <a
              className="plans-to-buy__list__epic-plan__more-information"
              href="https://epicgrouplab.com/courses">
                <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                  Conoce el plan trimestral
                </p>
                <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                  <img src={ChevronRightGreen} alt="chevron-right-green" />
                </figure>
              </a> */}

              <Link
              className="button--third--small plans-to-buy__list__epic-plan__button"
              to={`/sign-up-english-online-quarterly/${id}`}>
                <p className="paragraph--highlight">
                  Matricularse
                </p>
              </Link>
            </article>

            <div className="plans-container__middle-line-2"></div>

            <div className="plans-offer-two-sections-container videogames-online-monthly">
              <article className="plans-to-buy__list__epic-plan">
                <div className="plans-to-buy__list__epic-plan__title-container">
                  <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                    Curso
                    <br />
                    Master Videojuegos Online
                  </h3>
                </div>

                <p className="text--light-blue paragraph--standard">
                  <strong>Master Videojuegos Online</strong><br />
                  <br />
                  Esta llave te dará acceso anual a la comunidad y el pase VIP para eventos virtuales de Epic.
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  Valor
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    $165
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;pago único, impuestos no incluidos
                  </small>
                </div>
              </article>

              <div className="plans-to-buy__middle-line"></div>

              <article className="plans-to-buy__list__epic-plan">
                <p className="text--light-blue paragraph--standard">
                  <strong>Curso</strong><br />
                  <br />
                  El curso de master videojuegos online incluye 3 clases a la semana.
                </p>
                <p className="text--light-blue text--small">
                  <br />
                  Si tu sede más cercana cuenta con un centro físico, puedes consultar para acudir de forma presencial
                  a esas clases, aunque nuestros mentores seguirán enseñando en directo online.
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  Valor
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    $165
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;pago mensual, impuestos no incluidos
                  </small>
                </div>

                {/* <a
                className="plans-to-buy__list__epic-plan__more-information"
                href="https://epicgrouplab.com/courses">
                  <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                    Conoce el plan trimestral
                  </p>
                  <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                    <img src={ChevronRightGreen} alt="chevron-right-green" />
                  </figure>
                </a> */}

                <Link
                  className="button--third--small plans-to-buy__list__epic-plan__button"
                  to={`/sign-up-videogames-online-monthly/${id}`}
                >
                  <p className="paragraph--highlight">
                    Matricularse
                  </p>
                </Link>
              </article>
            </div>

            <article className="plans-to-buy__list__epic-plan videogames-online-quarterly">
              <div className="plans-to-buy__list__epic-plan__title-container">
                <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                  Curso
                  <br />
                  Master Videojuegos Online
                </h3>
              </div>
              <p className="text--light-blue paragraph--standard">
                <strong>Master Videojuegos Online</strong><br />
                <br />
                El curso de master videojuegos online incluye 3 clases a la semana.
              </p>
              <p className="text--light-blue text--small">
                <br />
                Si tu sede más cercana cuenta con un centro físico, puedes consultar para acudir de
                forma presencial a esas clases, aunque nuestros mentores seguirán enseñando en directo online.
              </p>
              <br />
              <p className="text--green plans-to-buy__list__epic-plan__description">
                Valor
              </p>
              <div className="plans-to-buy__list__call-to-action">
                <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                  $400
                </p>
                <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                  &nbsp;&nbsp;pago trimestral
                </small>
              </div>

              {/* <a
              className="plans-to-buy__list__epic-plan__more-information"
              href="https://epicgrouplab.com/courses">
                <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                  Conoce el plan trimestral
                </p>
                <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                  <img src={ChevronRightGreen} alt="chevron-right-green" />
                </figure>
              </a> */}

              <Link
                className="button--third--small plans-to-buy__list__epic-plan__button"
                to={`/sign-up-videogames-online-quarterly/${id}`}
              >
                <p className="paragraph--highlight">
                  Matricularse
                </p>
              </Link>
            </article>

            <div className="plans-container__middle-line-3"></div>

            <div className="plans-offer-two-sections-container entrepreneurship-technology-english-monthly">
              <article className="plans-to-buy__list__epic-plan">
                <div className="plans-to-buy__list__epic-plan__title-container">
                  <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                    Curso
                    <br />
                    Emprendimiento Digital + Tecnología + Inglés VR Online
                  </h3>
                </div>

                <p className="text--light-blue paragraph--standard">
                  <strong>Matrícula</strong><br />
                  <br />
                  Esta llave te dará acceso anual a la comunidad y el pase VIP para eventos virtuales de Epic.
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  Valor
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    $165
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;pago único
                  </small>
                </div>
              </article>

              <div className="plans-to-buy__middle-line"></div>

              <article className="plans-to-buy__list__epic-plan">
                <p className="text--light-blue paragraph--standard">
                  <strong>pago único</strong><br />
                  <br />
                  El curso de emprendimiento digital + tecnología + inglés vr online online incluye 4 clases a la semana.
                </p>
                <p className="text--light-blue text--small">
                  <br />
                  Puedes tomar el curso de Inglés VR completamente en línea si tienes gafas de VR (Oculus Quest 2).
                  Si no las tienes, puedes asistir en persona a tu sede más cercana, donde tendrás acceso a dispositivos VR,
                  con mentores en directo online.
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  Valor
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    $165
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;pago mensual
                  </small>
                </div>

                {/* <a
                className="plans-to-buy__list__epic-plan__more-information"
                href="https://epicgrouplab.com/courses">
                  <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                    Conoce el plan trimestral
                  </p>
                  <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                    <img src={ChevronRightGreen} alt="chevron-right-green" />
                  </figure>
                </a> */}

                <Link
                  className="button--third--small plans-to-buy__list__epic-plan__button"
                  to={`/sign-up-entrepreneurship-technology-english-online-monthly/${id}`}
                >
                  <p className="paragraph--highlight">
                    Matricularse
                  </p>
                </Link>
              </article>
            </div>

            <article className="plans-to-buy__list__epic-plan entrepreneurship-technology-english-quarterly">
              <div className="plans-to-buy__list__epic-plan__title-container">
                <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                  Curso
                  <br />
                  Emprendimiento Digital + Tecnología + Inglés VR Online
                </h3>
              </div>
              <p className="text--light-blue paragraph--standard">
                <strong>Curso</strong><br />
                <br />
                El curso de emprendimiento digital + tecnología + inglés vr online online incluye 4 clases a la semana.
              </p>
              <p className="text--light-blue text--small">
                <br />
                Puedes tomar el curso de Inglés VR completamente en línea si tienes gafas de VR (Oculus Quest 2).
                Si no las tienes, puedes asistir en persona a tu sede más cercana, donde tendrás acceso a dispositivos VR,
                con mentores en directo online.
              </p>
              <br />
              <p className="text--green plans-to-buy__list__epic-plan__description">
                Valor
              </p>
              <div className="plans-to-buy__list__call-to-action">
                <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                  $400
                </p>
                <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                  &nbsp;&nbsp;pago trimestral
                </small>
              </div>

              {/* <a
              className="plans-to-buy__list__epic-plan__more-information"
              href="https://epicgrouplab.com/courses">
                <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                  Conoce el plan trimestral
                </p>
                <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                  <img src={ChevronRightGreen} alt="chevron-right-green" />
                </figure>
              </a> */}

              <Link
                className="button--third--small plans-to-buy__list__epic-plan__button"
                to={`/sign-up-entrepreneurship-technology-english-online-quarterly/${id}`}
              >
                <p className="paragraph--highlight">
                  Matricularse
                </p>
              </Link>
            </article>
          </div>

          <div className="enrollment__oculus">
            <div className="plans-container__middle-line"></div>
            <div className="enrollment__oculus__content">
              <div>
                <h3 className="title--standard enrollment__oculus__title">Consigue tus gafas Meta Quest 2</h3>
                <p className="subtitle text--gray-blue enrollment__oculus__description">
                  Contacta con nosotros si quieres comprar o alquilar tus gafas de realidad virtual Meta Quest 2<br />
                  <br />
                  Alquiler desde <strong>96 $</strong> mensuales
                  <strong className="enrollment__oculus__description__another-color">(sin impuestos incluidos)</strong><br />
                  Compra desde <strong>480 $</strong> <strong className="enrollment__oculus__description__another-color">(sin
                  impuestos incluidos)</strong>
                </p>
                <a
                className="paragraph--highlight text--gray-blue"
                href="mailto:hola.houston@epicgrouplab.com?subject=Mayor%20información">
                  hola.houston@epicgrouplab.com
                </a>
              </div>
              <figure className="enrollment__oculus__image">
                <img src={VrOculus} alt="vr-oculus-headset" />
              </figure>
            </div>
          </div>

          <div className="enrollment__available-courses__contact-us">
            <div className="plans-container__middle-line"></div>
            <div className="enrollment__available-courses__contact-us__content">
              <div className="enrollment__available-courses__contact-us__text-container">
                <h3 className="subtitle enrollment__available-courses__contact-us__subtitle">Si tienes cualquier pregunta...</h3>
                <h2 className="title--xxl">¡No dudes en contactar con nosotros!</h2>
                <p className="subtitle enrollment__available-courses__contact-us__text-description">
                  ¡Pronto te contactaremos desde el equipo Epic para resolver tus dudas y ayudarte!
                </p>
                <div className="footer_email">
                  <a
                  className="text--gray-blue paragraph--highlight footer_email_email-address"
                  href="mailto:hola.houston@epicgrouplab.com?subject=Mayor%20información">
                    hola.houston@epicgrouplab.com
                  </a>
                </div>
              </div>
              <figure className="enrollment__available-courses__contact-us__image">
                <img src={BigManWorld} alt="big-man-world" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Enrollment